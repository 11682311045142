import { IMeResponse } from '../interfaces/services-interfaces/IMeResponse';
import { securedBaseApi } from './api.config';

export interface INewUserBody {
  country: {
    id: number;
  };
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  passwordConfirmation: string;
  role: string;
  company: {
    id: number;
  };
}

export interface IUserResponse {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  company: {
    id: number;
  };
  country: {
    id: number;
    name: string;
  };
  termV3Accepted: boolean;
}

export interface IUserList {
  content: IUserListItem[];
  totalElements: number;
  idsInDatabase: number[] | undefined;
}

export interface IUserListItem extends IMeResponse {
  identifierValue: string;
  optionsVisibility: boolean;
  indexInStore: number;
}

export interface ILinkToResetPassword {
  url: string;
}

export const administrativePanelUsersApi = {
  newUser: (newUserBody: INewUserBody) => {
    return securedBaseApi.post<INewUserBody>('/users', newUserBody);
  },
  newUserRegisterWithAHistorySolicitation: (newUser: INewUserBody) => {
    return securedBaseApi.post<INewUserBody>(
      `/users?checkForSolicitation=false`,
      newUser,
    );
  },
  getUsersList: (queryParams: string) => {
    return securedBaseApi.get<IUserList>(`/users${queryParams}`);
  },
  getUserById: (id: number) => {
    return securedBaseApi.get<IUserResponse>(`/users/${id}`);
  },
  updateUser: (id: number, updateUserBody: IUserResponse) => {
    return securedBaseApi.put<IUserResponse>(`/users/${id}`, updateUserBody);
  },
  deleteOne: (id: number) => {
    return securedBaseApi.delete(`/users/${id}`);
  },
  deleteMany: (ids: number | number[]) => {
    return securedBaseApi.post(`/users/batch/delete`, ids);
  },
  enableUser: (id: number) => {
    return securedBaseApi.patch(`/users/${id}/enable`);
  },
  disableUser: (id: number) => {
    return securedBaseApi.patch(`/users/${id}/disable`);
  },
  getLinkToResetPassword: (id: number) => {
    return securedBaseApi.get<ILinkToResetPassword>(
      `/users/${id}/access/restorepassword`,
    );
  },
};
