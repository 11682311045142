import { showModal } from '../showModal/showModal';
import { unhookedTranslation } from '../unhookedTranslation/unhookedTranslation';
import { asyncActions } from '../../pages/AdministrativePanel/Users/Users.Slice';
import { store } from '../../store/store';

export const showEnableOrDisableUserConfirmationModal = (
  id: number,
  rowItem: any,
) => {
  if (!rowItem.enabled) {
    showModal(
      'warning',
      `${unhookedTranslation(
        'administrative-panel-enable-user-warning-title',
      )}`,
      `${unhookedTranslation(
        'administrative-panel-enable-user-warning-content',
      )}`,
      () => {
        store.dispatch(asyncActions.ENABLE_USER(id));
      },
      () => {},
      `${unhookedTranslation('administrative-panel-enable-user-okText')}`,
      `${unhookedTranslation('administrative-panel-enable-user-cancelText')}`,
    );
  } else {
    showModal(
      'warning',
      `${unhookedTranslation(
        'administrative-panel-disable-user-warning-title',
      )}`,
      `${unhookedTranslation(
        'administrative-panel-disable-user-warning-content',
      )}`,
      () => {
        store.dispatch(asyncActions.DISABLE_USER(id));
      },
      () => {},
      `${unhookedTranslation('administrative-panel-disable-user-okText')}`,
      `${unhookedTranslation('administrative-panel-disable-user-cancelText')}`,
    );
  }
};
