import React, { useRef } from 'react';
import {
  Input,
  Button,
  Col,
  Row,
  Collapse,
  Form,
  Select,
  DatePicker,
} from 'antd';
import './Filter.scss';
import { useTranslation } from 'react-i18next';
import { FormInstance } from 'antd/lib/form';
import uniqid from 'uniqid';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  changeDatePickerMonthLabelBasedOnLocale,
  getDatePickerClassForTranslationBasedOnLocale,
  getDatePickerDateFormatsBasedOnLocale,
  getDatePickerLocale,
} from '../../helpers/datePickerTranslations/datePickerTranslations';

interface IFilter {
  openedFilter: boolean;
  fields: IFilterFields[];
  clearFilterFields: Function;
  submitFilterFields: Function;
}

interface IFilterFields {
  fieldTitle: string;
  fieldType: string;
  fieldProperty: string;
  fieldValue: string | number | undefined;
  selectOptions?: any[] | undefined;
  onChangeSelect?: Function;
  onChangeDatePicker?: Function;
  emptyCol?: boolean;
  buttonsCol?: boolean;
  placeholder?: string;
}

export const Filter: React.FC<IFilter> = ({
  openedFilter,
  fields,
  clearFilterFields,
  submitFilterFields,
}: IFilter) => {
  const currentLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const { t } = useTranslation();
  const filterFormRef = useRef(null);

  const clearFilterForm = () => {
    if (filterFormRef.current) {
      const formInstance = filterFormRef.current as unknown as FormInstance;
      clearFilterFields();
      setTimeout(() => {
        formInstance.resetFields();
      }, 0);
    }
  };

  const generateSelectOptions = (field: IFilterFields) => {
    if (field.selectOptions !== undefined) {
      return field.selectOptions.map((x) => {
        return (
          <Select.Option key={x.id} id={x.name} value={x.id}>
            {x.name}
          </Select.Option>
        );
      });
    }
  };

  const returnShowSearch = (field: any) => {
    const notReturnSearch = [
      t('term-listing-table-status'),
      t('administrative-panel-requests-filter-request-status'),
      t('administrative-panel-requests-filter-request-type'),
    ];
    if (notReturnSearch.includes(field)) {
      return false;
    }
    return true;
  };
  const generateInputOrSelect = (field: IFilterFields, index: any) => {
    switch (field.fieldType) {
      case 'input':
        return (
          <Input
            id={index}
            size="large"
            placeholder={field.placeholder ?? field.fieldTitle}
            allowClear
          />
        );

      case 'select':
        return (
          <Select
            placeholder={field.placeholder ?? field.fieldTitle}
            size="large"
            showSearch={returnShowSearch(field.fieldTitle)}
            allowClear
            notFoundContent={t('administrative-panel-empty-table')}
            optionFilterProp="children"
            onChange={(event: string | number) => {
              if (field.onChangeSelect) {
                field.onChangeSelect(event);
              }
            }}
          >
            {generateSelectOptions(field)}
          </Select>
        );
      case 'datePicker':
        return (
          <DatePicker
            dropdownClassName={getDatePickerClassForTranslationBasedOnLocale(
              currentLanguage,
            )}
            style={{ width: '100%' }}
            format={getDatePickerDateFormatsBasedOnLocale(currentLanguage)}
            locale={getDatePickerLocale(currentLanguage)}
            size="large"
            onChange={(date: any, dateString: string | string[]) => {
              if (field.onChangeDatePicker) {
                field.onChangeDatePicker(dateString);
              }
            }}
            onOpenChange={() => {
              setTimeout(() => {
                changeDatePickerMonthLabelBasedOnLocale(currentLanguage);
              }, 200);
            }}
            onPanelChange={() => {
              setTimeout(() => {
                changeDatePickerMonthLabelBasedOnLocale(currentLanguage);
              }, 200);
            }}
          />
        );
      default:
        break;
    }
  };

  const generateFieldsBlankOrButtons = (field: IFilterFields, index: any) => {
    if (field.emptyCol === true) {
      return <Col key={uniqid()} span={7} id={index} />;
    }

    if (field.buttonsCol === true) {
      return (
        <Col key={uniqid()} span={7} className="filter-button" id={index}>
          <Button type="link" onClick={() => clearFilterForm()}>
            {t('administrative-panel-filter-clear-button')}
          </Button>
          <Button shape="round" htmlType="submit">
            {t('administrative-panel-filter-filterButton')}
          </Button>
        </Col>
      );
    }

    return (
      <Col key={uniqid()} span={7} id={index}>
        <p id={index} className="filter-text-label">
          {field.fieldTitle}
        </p>
        <Form.Item
          key={`${uniqid()}${field.fieldValue}`}
          name={field.fieldProperty}
        >
          {generateInputOrSelect(field, index)}
        </Form.Item>
      </Col>
    );
  };

  const generateFilterFields = () => {
    const sublistedParams: JSX.Element[] = [];
    let emptyGroup: IFilterFields[] = [];

    fields.forEach((x, i) => {
      if (emptyGroup.length !== 3) {
        emptyGroup.push(x);

        if (emptyGroup.length === 3) {
          sublistedParams.push(
            <Row key={uniqid()} justify="space-around" align="middle">
              {emptyGroup.map((y, index) => {
                return generateFieldsBlankOrButtons(y, index);
              })}
            </Row>,
          );
          emptyGroup = [];
        }
      } else {
        sublistedParams.push(
          <Row justify="space-around" align="middle">
            {emptyGroup.map((y, index) => {
              return generateFieldsBlankOrButtons(y, index);
            })}
          </Row>,
        );
        emptyGroup = [];
        emptyGroup.push(x);
      }

      if (i === fields.length - 1) {
        const remainingCols = 3 - emptyGroup.length;

        if (remainingCols === 1) {
          emptyGroup.push({
            fieldType: '',
            fieldTitle: '',
            fieldProperty: '',
            fieldValue: '',
            emptyCol: false,
            buttonsCol: true,
          });
        } else if (remainingCols === 2) {
          emptyGroup.push({
            fieldType: '',
            fieldTitle: '',
            fieldProperty: '',
            fieldValue: '',
            emptyCol: true,
            buttonsCol: false,
          });

          emptyGroup.push({
            fieldType: '',
            fieldTitle: '',
            fieldProperty: '',
            fieldValue: '',
            emptyCol: false,
            buttonsCol: true,
          });
        } else if (remainingCols === 3) {
          emptyGroup.push({
            fieldType: '',
            fieldTitle: '',
            fieldProperty: '',
            fieldValue: '',
            emptyCol: true,
            buttonsCol: false,
          });

          emptyGroup.push({
            fieldType: '',
            fieldTitle: '',
            fieldProperty: '',
            fieldValue: '',
            emptyCol: true,
            buttonsCol: false,
          });

          emptyGroup.push({
            fieldType: '',
            fieldTitle: '',
            fieldProperty: '',
            fieldValue: '',
            emptyCol: false,
            buttonsCol: true,
          });
        }

        sublistedParams.push(
          <Row
            key={uniqid()}
            justify="space-around"
            align="middle"
            className="filter-row"
          >
            {emptyGroup.map((y, index) => {
              return generateFieldsBlankOrButtons(y, index + 3);
            })}
          </Row>,
        );
      }
    });

    return sublistedParams;
  };

  const getInitialValuesToForm = () => {
    const initialValues: any = {};

    fields.forEach((x) => {
      initialValues[x.fieldProperty] = x.fieldValue;
    });

    return initialValues;
  };

  return (
    <Collapse
      bordered={false}
      activeKey={openedFilter ? '1' : ''}
      className="Filter"
    >
      <Collapse.Panel
        key="1"
        showArrow={false}
        header=""
        id="filter-collapse-panel"
      >
        <Row>
          <Col span={24}>
            <Form
              ref={filterFormRef}
              initialValues={getInitialValuesToForm()}
              onFinish={(formValues) => submitFilterFields(formValues)}
            >
              {generateFilterFields()}
            </Form>
          </Col>
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
};
