import { Button, Col, Input, Row, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ReactComponent as ResetPassword } from '../../../../assets/popover-icons/reset-password.svg';
import { ReactComponent as EditPencil } from '../../../../assets/popover-icons/edit.svg';
import { ReactComponent as Padlock } from '../../../../assets/popover-icons/padlock.svg';
import { ReactComponent as TrashCan } from '../../../../assets/popover-icons/trash-can.svg';
import { ReactComponent as Redirect } from '../../../../assets/blue-redirect.svg';
import { DeleteSelectedButton } from '../../../../components/DeleteSelectedButton/DeleteSelectedButton';
import { Filter } from '../../../../components/Filter/Filter';
import {
  IDefaultSortBehaviour,
  PagingTable,
} from '../../../../components/PagingTable/PagingTable';
import { ShowFiltersButton } from '../../../../components/ShowFiltersButton/ShowFiltersButton';
import { showModal } from '../../../../helpers/showModal/showModal';
import { AppDispatch, RootState } from '../../../../store/store';
import { asyncActions, UsersSlice } from '../Users.Slice';
import { asyncActions as asyncActionsCompanies } from '../../Companies/CompanyAdministrativePanel.Slice';
import './UsersListing.scss';
import { scrollToTopFunction } from '../../../../helpers/useScrollToTop/useScrollToTop';

export const UsersListing: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const usersState = useSelector((state: RootState) => state.users);
  const usersListState = useSelector((state: RootState) => state.users.list);
  const companyState = useSelector(
    (state: RootState) => state.CompanyAdministrativePanel,
  );

  useEffect(() => {
    dispatch(asyncActionsCompanies.COMPANY_LIST_FOR_USERS());
    dispatch(asyncActionsCompanies.COUNTRIES_LIST());
  }, [dispatch]);

  useEffect(() => {
    if (!usersListState.shouldRefresh) {
      return;
    }

    if (usersListState.tableLoading) {
      return;
    }

    let queryString = `?page=${usersListState.pageIndex - 1}&size=${
      usersListState.pageSize
    }`;

    if (usersListState.userQueryString.length > 0) {
      queryString = `${queryString}&${usersListState.userQueryString.join('&')}`;
    }

    if (usersListState.sortBy) {
      queryString = `${queryString}&${usersListState.sortBy}`;
    }

    if (usersListState.userFirstQueryBehaviour) {
      queryString = `${queryString}&sort=firstName,ignoreCase`;
    }

    dispatch(asyncActions.USERS_LIST(queryString));
  }, [dispatch, usersListState]);

  const [columnsWidth, setColumnsWidth] = useState({
    firstNameColumnWidth: 4.2,
    lastNameColumnWidth: 4.2,
    emailColumnWidth: 4.2,
    companyColumnWidth: 4.2,
    countryColumnWidth: 3.5,
  });

  const resizeHandlerSpeed = 0.028;
  const resizeColumns = (
    columnName:
      | 'firstNameColumnWidth'
      | 'lastNameColumnWidth'
      | 'emailColumnWidth'
      | 'companyColumnWidth'
      | 'countryColumnWidth',
    movement: number,
  ) => {
    setColumnsWidth({
      ...columnsWidth,
      [columnName]: (columnsWidth[columnName] += movement * resizeHandlerSpeed),
    });
  };
  const usersListingCursor = [
    {
      columnTitle: t('administrative-panel-users-tab-name'),
      columnProperty: 'firstName',
      width: columnsWidth.firstNameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('firstNameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-users-tab-lastName'),
      columnProperty: 'lastName',
      width: columnsWidth.lastNameColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('lastNameColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-users-tab-email'),
      columnProperty: 'email',
      width: columnsWidth.emailColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('emailColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-users-tab-company'),
      columnProperty: ['company', 'name'],
      width: columnsWidth.companyColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('companyColumnWidth', event.movementX);
      },
    },
    {
      columnTitle: t('administrative-panel-users-tab-country'),
      columnProperty: ['countryName'],
      width: columnsWidth.countryColumnWidth,
      onResizeAction: (event: MouseEvent) => {
        resizeColumns('countryColumnWidth', event.movementX);
      },
    },
  ];

  const getUserById = (id: number) => {
    if (!usersState.showEditMode) {
      navigate(`/administrative-panel/add_or_edit?id=${id}&tab=users`);
    }
  };

  const mockedOptions = [
    {
      optionName: t('administrative-panel-users-table-column-edit-option'),
      optionIcon: <EditPencil />,
      optionOnClick: (id: number) => {
        getUserById(id);
      },
    },
    {
      optionId: 'enable-disable-user',
      optionName: 'enable-disable-user',
      optionIcon: <Padlock />,
      optionOnClick: () => {},
    },
    {
      optionName: t('administrative-panel-users-table-column-delete-option'),
      optionIcon: <TrashCan />,
      optionOnClick: (id: number) => showDeleteConfirmationModal(id),
    },
    {
      optionName: t('administrative-panel-user-reset-password'),
      optionIcon: <ResetPassword />,
      optionOnClick: (id: number) => showResetPasswordLinkModal(id),
    },
  ];

  const toggleFilter = () => {
    if (usersState.showFilter === '') {
      dispatch(UsersSlice.actions.FILTER_STATUS_CHANGE('1'));
    } else {
      dispatch(UsersSlice.actions.FILTER_STATUS_CHANGE(''));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(
        UsersSlice.actions.USER_BACK_TO_ORIGINAL_STATE_PERSISTING_FILTER(),
      );
    };
  }, [dispatch]);

  const onChangePage = (pageIndex: number) => {
    scrollToTopFunction('new-user-button');
    dispatch(UsersSlice.actions.USERS_CHANGE_PAGE(pageIndex));
  };

  // UI ELEMENTS
  const showDeleteConfirmationModal = (id: number) => {
    showModal(
      'warning',
      `${t('administrative-panel-delete-user-warning-title')}`,
      `${t('administrative-panel-delete-user-warning-content')}`,
      () => {
        dispatch(asyncActions.DELETE_ONE_USER(id));
      },
      () => {},
      `${t('administrative-panel-okText-delete')}`,
      `${t('administrative-panel-cancelText-delete')}`,
    );
  };

  const showResetPasswordLinkModal = (id: number) => {
    dispatch(asyncActions.GET_LINK_TO_RESET_PASSWORD(id));
  };

  const resetPasswordModalContent = useCallback(() => {
    return (
      <div className="ResetPasswordModalContent">
        <p className="reset-password-paragraph">
          {t('administrative-panel-user-reset-password-modal-paragraph')}
        </p>
        <Input
          defaultValue={usersState.linkToResetPassoword}
          placeholder={t(
            'administrative-panel-user-reset-password-modal-input-placeholder',
          )}
          className="reset-password-link-input"
          readOnly
        />
        <CopyToClipboard
          text={
            usersState.linkToResetPassoword
              ? usersState.linkToResetPassoword
              : ''
          }
        >
          <Tooltip
            autoAdjustOverflow
            placement="top"
            trigger={['click']}
            color="#46B8A6"
            title={
              <div>
                <CheckCircleOutlined />{' '}
                {t('administrative-panel-user-link-copied-notification')}
              </div>
            }
          >
            <Redirect className="reset-password-redirect-icon" />
          </Tooltip>
        </CopyToClipboard>
      </div>
    );
  }, [usersState.linkToResetPassoword, t]);

  useEffect(() => {
    if (usersState.showResetPasswordModal) {
      return showModal(
        'info',
        t('administrative-panel-user-reset-password'),
        resetPasswordModalContent(),
        () => {
          dispatch(UsersSlice.actions.CLOSE_RESET_PASSWORD_MODAL());
        },
        () => {
          dispatch(UsersSlice.actions.CLOSE_RESET_PASSWORD_MODAL());
        },
        t('administrative-panel-user-close-modal-label'),
        '',
        undefined,
        'reset-password-modal',
      );
    }
  }, [
    dispatch,
    t,
    usersState.showResetPasswordModal,
    resetPasswordModalContent,
  ]);

  const selectRowsToUsers = (x: any) => {
    dispatch(UsersSlice.actions.USERS_CREATED_SET_SELECTED_ROWS(x));
  };

  const selectIdFromRowsInUsers = (x: any) => {
    dispatch(UsersSlice.actions.USERS_CREATED_SET_ID_TO_DELETE_USERS(x));
  };

  const deleteSelectedUsers = (id: number[]) => {
    dispatch(asyncActions.DELETE_MANY_USERS(id));
  };

  const clearFilterFields = () => {
    dispatch(UsersSlice.actions.CLEAR_FILTER_FIELDS());
  };

  const defaultSortBehaviour: IDefaultSortBehaviour = {
    columnProperty: 'firstName',
    order: 'ascend',
    disableDefaultSortBehaviour: () => {
      if (usersListState.userFirstQueryBehaviour) {
        dispatch(UsersSlice.actions.DISABLE_DEFAULT_SORT_BEHAVIOUR());
      }
    },
  };

  const dispatchSortBy = (sortBy: string) => {
    dispatch(UsersSlice.actions.USERS_SORT_BY(sortBy ? `sort=${sortBy}` : ''));
  };

  const onChangeSort = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    let sortBy = '';

    if (!order || !field) {
      return dispatchSortBy(sortBy);
    }

    if (defaultSortBehaviour) {
      defaultSortBehaviour.disableDefaultSortBehaviour();
    }

    const fieldToQueryParam = Array.isArray(field) ? field[0] : field;

    switch (fieldToQueryParam) {
      case 'firstName':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'lastName':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'email':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'company':
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam}.name,ignoreCase`
            : `${fieldToQueryParam}.name,desc,ignoreCase`;
        return dispatchSortBy(sortBy);

      case 'countryName':
        sortBy = order === 'ascend' ? 'country' : `country,desc`;
        return dispatchSortBy(sortBy);

      case 'status':
        break;

      default:
        sortBy =
          order === 'ascend'
            ? `${fieldToQueryParam},ignoreCase`
            : `${fieldToQueryParam},desc,ignoreCase`;
        return dispatchSortBy(sortBy);
    }
  };

  const submitFilter = (values: any) => {
    const queryParams: string[] = [];
    Object.keys(values).forEach((key) => {
      switch (key) {
        case 'firstName':
          dispatch(UsersSlice.actions.FILTER_SET_NAME(values[key]));
          break;
        case 'lastName':
          dispatch(UsersSlice.actions.FILTER_SET_LASTNAME(values[key]));
          break;
        case 'email':
          dispatch(UsersSlice.actions.FILTER_SET_EMAIL(values[key]));
          break;
        case 'companyId':
          dispatch(UsersSlice.actions.FILTER_SET_COMPANY(values[key]));
          break;
        case 'countryId':
          dispatch(UsersSlice.actions.FILTER_SET_COUNTRY(values[key]));
          break;
        default:
          break;
      }

      if (values[key]) {
        queryParams.push(`${key}=${values[key]}`);
      }
    });

    dispatch(UsersSlice.actions.SET_FILTER_QUERY_STRING(queryParams));
  };

  const countriesListing = () => {
    const countries = companyState.companies.companyRegister.countriesList.map(
      (x) => {
        return {
          ...x,
          name: t(x.name),
        };
      },
    );

    return countries.sort((a, b) =>
      a.name.localeCompare(b.name, undefined, { sensitivity: 'base' }),
    );
  };

  const fields = [
    {
      fieldTitle: t('administrative-panel-filter-by-name'),
      fieldType: 'input',
      fieldProperty: 'firstName',
      fieldValue: usersListState.filter.filterByFirstName,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-lastname'),
      fieldType: 'input',
      fieldProperty: 'lastName',
      fieldValue: usersListState.filter.filterByLastName,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-email'),
      fieldType: 'input',
      fieldProperty: 'email',
      fieldValue: usersListState.filter.filterByEmail,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-company'),
      fieldType: 'select',
      fieldProperty: 'companyId',
      fieldValue: usersListState.filter.filterByCompany,
      selectOptions: companyState.companies.dataForUsers,
    },
    {
      fieldTitle: t('administrative-panel-filter-by-country'),
      fieldType: 'select',
      fieldProperty: 'countryId',
      fieldValue: usersListState.filter.filterByCountry,
      selectOptions: countriesListing(),
    },
  ];

  const dataSource = () => {
    return usersListState.data.map((x) => {
      return {
        ...x,
        countryName: t(x.country.name),
      };
    });
  };

  return (
    <Row className="UsersListing">
      <Row className="buttons-filters" justify="space-between">
        <Col span={3}>
          <Button
            id="new-user-button"
            className="users-listing-button-new-user"
            onClick={() =>
              navigate('/administrative-panel/add_or_edit?tab=users')
            }
          >
            {t('administrative-panel-new-user-button-label')}
          </Button>
        </Col>
        <Col pull={6}>
          <DeleteSelectedButton
            numberOfItemsSelected={usersListState.selectedRowToDeleteUsers}
            messageConfirmationByGivenTab={`${t(
              'administrative-panel-delete-button',
            )}`}
            singularContentConfirmationByGivenTab={`${t(
              'administrative-panel-one-user',
            )}`}
            pluralContentConfirmationByGivenTab={`${t(
              'administrative-panel-many-users',
            )}`}
            deleteOneTitleModal={`${t(
              'administrative-panel-delete-user-warning-title',
            )}`}
            deleteOneContentModal={`${t(
              'administrative-panel-delete-user-warning-content',
            )}`}
            deleteManyTitleModal={`${t(
              'administrative-panel-delete-many-users-warning-title',
            )}`}
            deleteManyContentModal={`${t(
              'administrative-panel-delete-many-users-warning-content',
            )}`}
            onOkText={`${t('administrative-panel-okText-delete')}`}
            onCancelText={`${t('administrative-panel-cancelText-delete')}`}
            deleteAction={() =>
              deleteSelectedUsers(usersListState.deleteManyUsersIds!)
            }
          />
        </Col>
        <ShowFiltersButton
          onToggle={toggleFilter}
          openedFilter={usersState.showFilter === ''}
        />
      </Row>
      <Row className="filter-collapse-area">
        <Col span={24}>
          <Filter
            clearFilterFields={clearFilterFields}
            openedFilter={usersState.showFilter !== ''}
            fields={fields}
            submitFilterFields={submitFilter}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PagingTable
            emptyText={t('administrative-panel-empty-table')}
            dataSource={dataSource()}
            tableLoading={usersListState.tableLoading}
            columns={usersListingCursor}
            options={mockedOptions}
            pageSize={usersListState.pageSize}
            pageIndex={usersListState.pageIndex}
            numberOfItems={usersListState.numberOfItems}
            onChangePage={onChangePage}
            onChangeSort={onChangeSort}
            rowClickAction={(record: any) => getUserById(record.id)}
            selectRowsToGivenTab={(x: any) => selectRowsToUsers(x)}
            selectIdFromRowsSelected={(x: any) => selectIdFromRowsInUsers(x)}
          />
        </Col>
      </Row>
    </Row>
  );
};
