import React from 'react';
import { Row, Col, Table, Menu, Tag, Tooltip } from 'antd';
import {
  TableRowSelection,
  TablePaginationConfig,
} from 'antd/lib/table/interface';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';

import './PagingTable.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Padlock } from '../../assets/popover-icons/padlock.svg';
import { RootState } from '../../store/store';
import { showEnableOrDisableUserConfirmationModal } from '../../helpers/showEnableOrDisableUserConfirmationModal/showEnableOrDisableUserConfirmationModal';
import { showEnableOrDisableNewsConfirmationModal } from '../../helpers/showEnableOrDisableNewsConfirmation/showEnableOrDisableNewsConfirmation';
import { POP_UP_LANGUAGE_MAP_INVERTED } from '../../services/administrative-panel-pop-up';

interface IPagingTable {
  dataSource: any[];
  tableLoading: boolean;
  columns: IColumnCursor[];
  options: ITupleOptions[];
  pageSize: number;
  pageIndex: number;
  numberOfItems: number;
  onChangePage: Function;
  onChangeSort: Function;
  rowClickAction: Function;
  emptyText: string;
  disableOptionsColumn?: boolean;
  disableSelectionColumn?: boolean;
  disableRowCursorPointer?: boolean;
  defaultSortBehaviour?: IDefaultSortBehaviour;
  getIdFromRow?: (rowData: any) => number;
  selectRowsToGivenTab: Function;
  selectIdFromRowsSelected: Function;
}

interface IColumnCursor {
  columnTitle: string;
  columnProperty: string | string[];
  className?: string;
  disableSort?: boolean;
  width?: number;
  onResizeAction?: any;
}

interface ITupleOptions {
  optionId?: string;
  optionName: string;
  optionIcon: JSX.Element;
  optionOnClick: Function;
  optionDisableVerification?: Function;
  optionDisabledMessage?: string;
}

export interface IDefaultSortBehaviour {
  columnProperty: string;
  disableDefaultSortBehaviour: Function;
  order: 'ascend' | 'descend' | null | undefined;
}

export const PagingTable: React.FC<IPagingTable> = ({
  dataSource,
  tableLoading,
  columns,
  options,
  pageSize,
  pageIndex,
  numberOfItems,
  onChangePage,
  onChangeSort,
  rowClickAction,
  emptyText,
  disableOptionsColumn = false,
  disableSelectionColumn = false,
  disableRowCursorPointer = false,
  defaultSortBehaviour,
  getIdFromRow = (rowData: any) => rowData.id,
  selectRowsToGivenTab,
  selectIdFromRowsSelected,
}: IPagingTable) => {
  const applicationLanguage = useSelector(
    (state: RootState) => state.navBar.defaultLanguage,
  );

  const dateColumnsProperties = ['updatedAt', 'createdAt', 'modifiedAt'];

  const languageValue = Array.from(POP_UP_LANGUAGE_MAP_INVERTED).find(
    ([value]) => applicationLanguage === value,
  );

  const { t } = useTranslation();

  const generateEnableOrDisableUserOption = (rowItem: any) => {
    if (!rowItem.enabled) {
      return (
        <Tooltip
          placement="left"
          title={t(
            'administrative-panel-users-table-column-enable-user-option',
          )}
        >
          <div>
            <Padlock />
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        placement="left"
        title={t('administrative-panel-users-table-column-disable-user-option')}
      >
        <div>
          <Padlock />
        </div>
      </Tooltip>
    );
  };

  const generateEnableOrDisableNewsOption = (rowItem: any) => {
    if (!rowItem.status) {
      return (
        <Tooltip
          placement="left"
          title={t('administrative-panel-news-table-column-enable-option')}
        >
          <div>
            <Padlock />
          </div>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        placement="left"
        title={t('administrative-panel-news-table-column-disable-option')}
      >
        <div>
          <Padlock />
        </div>
      </Tooltip>
    );
  };

  const generateOptions = (
    menuOptions: ITupleOptions[],
    itemId: number,
    rowItem: any,
  ) => {
    const menuOptionItens = menuOptions
      .filter(
        (x) =>
          x.optionId !== 'enable-disable-user' &&
          x.optionId !== 'enable-disable-news',
      )
      .map((x) => {
        const itemWithIconAndName = (
          <Tooltip placement="left" title={x.optionName}>
            <div>{x.optionIcon}</div>
          </Tooltip>
        );

        const disableItem = x.optionDisableVerification
          ? x.optionDisableVerification(itemId, rowItem)
          : undefined;

        const disabledItemWithTooltip = (
          <Tooltip placement="left" title={x.optionDisabledMessage}>
            <div>{x.optionIcon}</div>
          </Tooltip>
        );

        return (
          <Menu.Item
            key={x.optionName.toLowerCase()}
            onClick={() => {
              x.optionOnClick(itemId, rowItem);
            }}
            disabled={disableItem}
          >
            {disableItem ? disabledItemWithTooltip : itemWithIconAndName}
          </Menu.Item>
        );
      });

    if (menuOptions.find((x) => x.optionId === 'enable-disable-user')) {
      menuOptionItens.push(
        <Menu.Item
          key={uniqid()}
          onClick={() => {
            showEnableOrDisableUserConfirmationModal(itemId, rowItem);
          }}
        >
          {generateEnableOrDisableUserOption(rowItem)}
        </Menu.Item>,
      );
    }
    if (menuOptions.find((x) => x.optionId === 'enable-disable-news')) {
      menuOptionItens.push(
        <Menu.Item
          key={uniqid()}
          onClick={() => {
            showEnableOrDisableNewsConfirmationModal(itemId, rowItem);
          }}
        >
          {generateEnableOrDisableNewsOption(rowItem)}
        </Menu.Item>,
      );
    }

    return (
      <Menu className="paging-table-options-column">{menuOptionItens}</Menu>
    );
  };

  const formatDateColumn = (unformattedDate: string) => {
    const date = new Date(unformattedDate);

    const dateFormat = new Intl.DateTimeFormat(applicationLanguage, {
      dateStyle: 'long',
      timeStyle: 'long',
    } as Intl.DateTimeFormatOptions);

    return <span> {dateFormat.format(date)} </span>;
  };

  const checkIfColumnHasDefaultSortBehaviour = (columnProperty: string) => {
    if (
      defaultSortBehaviour &&
      defaultSortBehaviour.columnProperty === columnProperty
    ) {
      return defaultSortBehaviour.order;
    }

    return undefined;
  };

  const generateColumns = () => {
    let tableColumns: JSX.Element[] = [];

    tableColumns = columns.map((x) => {
      if (dateColumnsProperties.includes(x.columnProperty as string)) {
        const isPageTable = window.location.href.includes('edit-pages');
        return (
          <Table.Column
            title={x.columnTitle}
            key={x.columnProperty.toString()}
            sorter
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            ellipsis
            defaultSortOrder={checkIfColumnHasDefaultSortBehaviour(
              x.columnProperty as string,
            )}
            showSorterTooltip={false}
            render={
              isPageTable
                ? (y: any) => formatDateColumn(y.page.modifiedAt as string)
                : (y: any) => formatDateColumn(y[x.columnProperty as string])
            }
            width="4.5rem"
          />
        );
      }

      if (x.columnProperty === 'titleLanguage') {
        return (
          <Table.Column
            title={x.columnTitle}
            render={(y: any) => {
              return y.contents.find(
                (e: any) =>
                  e.language ===
                  (languageValue ? languageValue[1] : 'PORTUGUESE'),
              ).title;
            }}
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
        );
      }

      if (x.columnProperty === 'subtitleLanguage') {
        return (
          <Table.Column
            title={x.columnTitle}
            render={(y: any) =>
              y.contents.find(
                (e: any) =>
                  e.language ===
                  (languageValue ? languageValue[1] : 'PORTUGUESE'),
              ).subtitle
            }
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
        );
      }

      return (
        <>
          <Table.Column
            title={x.columnTitle}
            dataIndex={x.columnProperty}
            key={x.columnTitle}
            onCell={(record: any) => {
              return {
                onClick: () => rowClickAction(record),
              };
            }}
            className={x.className}
            sorter={x.disableSort ? !x.disableSort : true}
            ellipsis
            showSorterTooltip={false}
            width={x.width ? `${x.width}rem` : '4.2rem'}
          />
          <Table.Column
            className="resizeHandle"
            onHeaderCell={() => {
              return {
                onMouseDown: () => {
                  window.addEventListener('mousemove', x.onResizeAction);
                  window.addEventListener('mouseup', () => {
                    window.removeEventListener('mousemove', x.onResizeAction);
                  });
                },
              };
            }}
          />
        </>
      );
    });

    if (window.location.href.includes('administrative-panel?tab=users')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="status"
          align="left"
          showSorterTooltip={false}
          render={(record) => {
            return (
              <Tag color={record.enabled ? 'green' : 'red'} key="statusTag">
                {record.enabled
                  ? t('administrative-panel-user-status-column-unlocked')
                  : t('administrative-panel-user-status-column-blocked')}
              </Tag>
            );
          }}
          width="3.125rem"
        />,
      );
    }

    if (window.location.href.includes('administrative-panel?tab=news')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="active"
          align="left"
          sorter
          showSorterTooltip={false}
          defaultSortOrder="ascend"
          render={(record) => {
            return (
              <Tag color={record.active ? 'green' : 'red'} key="statusTag">
                {record.active
                  ? t('news-listing-table-status-active')
                  : t('news-listing-table-status-inactive')}
              </Tag>
            );
          }}
          width="3rem"
        />,
      );
    }

    if (window.location.href.includes('administrative-panel?tab=terms')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="active"
          align="left"
          sorter
          showSorterTooltip={false}
          defaultSortOrder="descend"
          render={(record) => {
            return (
              <Tag color={record.active ? 'green' : 'red'} key="statusTag">
                {record.active
                  ? t('terms-listing-active-status')
                  : t('terms-listing-inactive-status')}
              </Tag>
            );
          }}
          width="3.125rem"
        />,
      );
    }

    if (window.location.href.includes('administrative-panel?tab=pop-up')) {
      tableColumns.push(
        <Table.Column
          title="Status"
          key="active"
          align="left"
          sorter
          showSorterTooltip={false}
          defaultSortOrder="descend"
          render={(record) => {
            return (
              <Tag color={record.active ? 'green' : 'red'} key="statusTag">
                {record.active ? t('active-status') : t('inactive-status')}
              </Tag>
            );
          }}
          width="3.125rem"
        />,
      );
    }

    if (!disableOptionsColumn) {
      tableColumns.push(
        <Table.Column
          title=""
          key="options"
          className="optionsColumn"
          ellipsis
          align="center"
          render={(x: any) => generateOptions(options, getIdFromRow(x), x)}
          width={`${1.1 * options.length}rem`}
        />,
      );
    }

    return tableColumns;
  };

  const rowSelectionProperties: TableRowSelection<any> = {
    preserveSelectedRowKeys: true,
    type: 'checkbox',
    onChange: (selectedRowKeys, selectedRows) => {
      selectRowsToGivenTab(selectedRows);

      selectIdFromRowsSelected(selectedRows.map((y) => getIdFromRow(y)));
    },
    columnWidth: '1.25rem',
  };

  const paginationConfig: TablePaginationConfig = {
    total: numberOfItems,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} ${t('paging-table-pagination-of')} ${total}`,
    current: pageIndex,
    defaultPageSize: pageSize,
    pageSizeOptions: [],
    position: ['bottomCenter'],
  };

  const sortHandler = (
    field: string | string[],
    order: string | undefined | null,
  ) => {
    onChangeSort(field, order);
  };

  return (
    <Row className="PagingTable">
      <Col span={24}>
        <Table
          onChange={(pagination, filters, sorter: any) => {
            const sorterField = sorter.field ? sorter.field : sorter.columnKey;

            sortHandler(sorterField, sorter.order);
            onChangePage(pagination.current);
          }}
          locale={{ emptyText }}
          loading={tableLoading}
          rowSelection={
            disableSelectionColumn ? undefined : rowSelectionProperties
          }
          dataSource={dataSource}
          pagination={paginationConfig}
          rowKey={(record) => getIdFromRow(record)}
          className={
            disableRowCursorPointer
              ? 'ant-table-row-disabled-cursor'
              : undefined
          }
        >
          {generateColumns()}
        </Table>
      </Col>
    </Row>
  );
};
